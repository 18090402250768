// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCQ4MX93IcHFHN8gKeQ6G8ttD_ptC22eW4",
  authDomain: "hddfcc2.firebaseapp.com",
  databaseURL: "https://hddfcc2-default-rtdb.firebaseio.com",
  projectId: "hddfcc2",
  storageBucket: "hddfcc2.appspot.com",
  messagingSenderId: "722386275271",
  appId: "1:722386275271:web:0c74cca7dbf5b9e52ca7e2",
  measurementId: "G-RBQLV2FKQS"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };